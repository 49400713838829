code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
}

* {
  scrollbar-color: var(--power3) var(--white);
}

::-webkit-scrollbar-track{
  background-color: var(--white);
}
::-webkit-scrollbar-thumb{
  background-color: var(--power3);
}
::-webkit-scrollbar-thumb:hover{
  background-color: var(--hover);
}

/* html {
  scroll-behavior: smooth;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(10px + 2vmin);
  background-color: var(--bg1-dark);
  outline: var(--border-size) solid white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ---------- */
/* COLORS */

:root {
  /* --ff-primary: "Open Sans", Sans-Serif;
  --ff-secondary: "Raleway", sans-serif; */
  --border-size: 3px;

  --text-dark: hsl(150, 5%, 90%);
  --bg1-dark: hsl(150, 5%, 10%);
  --bg2-dark: hsl(150, 5%, 15%);
  --bg3-dark: hsl(150, 5%, 20%);

  --thought-dark: hsl(240, 50%, 80%);
  --Aldred-dark: hsl(100, 50%, 80%);
  --Tez-dark: hsl(0, 50%, 80%);

  --text-light: hsl(150, 5%, 30%);
  --og-bg1-light: hsla(210, 95%, 80%, 1);
  --og-bg2-light: hsla(210, 95%, 85%, 1);
  --og-bg3-light: hsla(210, 95%, 90%, 1);
  --bg1-light: hsla(210, 95%, 80%, 1);
  --bg2-light: hsla(210, 95%, 85%, 1);
  --bg3-light: hsla(210, 95%, 90%, 1);

  --hover: hsl(297, 55%, 44%);
  --focus: hsl(297, 100%, 58%);
  --power1: rgb(0, 181, 124);
  --power2: hsl(200, 100%, 75%);
  --power3: hsl(200, 100%, 50%);
  --thought-light: hsl(240, 50%, 45%);
  --Aldred-light: hsl(150, 50%, 50%);
  --Tez-light: hsl(0, 60%, 40%);

  /* Syncro Colors */
  --white: hsl(255, 0%, 100%);
  --white-dark: hsl(255, 10%, 100%);
  --blue-light: hsl(202, 100%, 36%);
  --blue: hsl(202, 100%, 26%);
  --blue-dark: hsl(202, 100%, 16%);
  --blue-darker: hsl(202, 100%, 6%);
  --baby-blue: hsl(194.7, 53.3%, 90%);
  --baby-blue-dark: hsl(194.7, 53.3%, 80%);
  --hot-pink: hsl(341, 85%, 55%);
  --hot-pink-dark: hsl(341, 85%, 50%);
  --screw: hsl(45, 100%, 65%);
}
@media screen and (min-width: 500px) {
  :root {
    --border-size: 5px;
  }
}

/* ---------- */
/* CLASSES */

#root {
	position: relative;
}

.fullWidth {
  width: 100%;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.betweenFlex {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.columnFlexStart {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.noTopMargin {
  margin-top: 0;
}
.noBottomMargin {
  margin-bottom: 0;
}
.autoHorizontalMargin {
  margin-left: auto;
  margin-right: auto;
}
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version: Chrome, Edge, Opera and Firefox */
}

/* CLASS INVOLVING MEDIA QUERIES */
.scalingTopAndBottomMargin {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.scalingBottomMargin {
  margin-bottom: 1rem;
}
.scalingPadding {
  padding: 1rem;
}
.scalingBottomPadding {
  padding-bottom: 1rem;
}
.scalingTopPadding {
  padding-top: 1rem;
}
.halfScalingTopPadding {
  padding-top: 0.5rem;
}
.halfScalingBottomMargin {
  margin-bottom: 0.5rem;
}
.doubleScalingBottomMargin {
  margin-bottom: 2rem;
}
.hugeScalingBottomMargin {
  margin-bottom: 6rem;
}
.smallScalingText {
  font-size: calc(6px + 2vmin);
}
.mediumScalingText {
  font-size: calc(6px + 3vmin);
}
.largeScalingText {
  font-size: calc(8px + 5vmin);
}
.largerScalingText {
  font-size: calc(10px + 5vmin);
}
@media screen and (min-width: 300px) {
  .smallScalingText {
    font-size: calc(6px + 3vmin);
  }
  .mediumScalingText {
    font-size: calc(6px + 4vmin);
  }
  .largeScalingText {
    font-size: calc(8px + 6vmin);
  }
  .largerScalingText {
    font-size: calc(10px + 6vmin);
  }
}
@media screen and (min-width: 500px) {
  .scalingTopAndBottomMargin {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .scalingBottomMargin {
    margin-bottom: 2rem;
  }
  .scalingPadding {
    padding: 2rem;
  }
  .scalingBottomPadding {
    padding-bottom: 2rem;
  }
  .scalingTopPadding {
    padding-top: 2rem;
  }
  .halfScalingBottomMargin {
    margin-bottom: 1rem;
  }
  .halfScalingTopPadding {
    padding-top: 1rem;
  }
  .doubleScalingBottomMargin {
    margin-bottom: 4rem;
  }
  .hugeScalingBottomMargin {
    margin-bottom: 9rem;
  }
}
@media screen and (min-width: 800px) {
  .scalingTopAndBottomMargin {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }
  .scalingBottomMargin {
    margin-bottom: 2.5rem;
  }
  .scalingPadding {
    padding: 2.5rem;
  }
  .scalingBottomPadding {
    padding-bottom: 2.5rem;
  }
  .scalingTopPadding {
    padding-top: 2.5rem;
  }
  .halfScalingBottomMargin {
    margin-bottom: 1.25rem;
  }
  .halfScalingTopPadding {
    padding-top: 1.25rem;
  }
  .doubleScalingBottomMargin {
    margin-bottom: 5rem;
  }
  .smallScalingText {
    font-size: calc(8px + 2vmin);
  }
  .mediumScalingText {
    font-size: calc(10px + 2vmin);
  }
  .largeScalingText {
    font-size: calc(12px + 3vmin);
  }
  .largerScalingText {
    font-size: calc(14px + 3vmin);
  }
}
@media screen and (min-width: 1000px) {
  .scalingTopAndBottomMargin {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
  .scalingBottomMargin {
    margin-bottom: 3rem;
  }
  .scalingPadding {
    padding: 3rem;
  }
  .scalingBottomPadding {
    padding-bottom: 3rem;
  }
  .scalingTopPadding {
    padding-top: 3rem;
  }
  .halfScalingBottomMargin {
    margin-bottom: 1.5rem;
  }
  .halfScalingTopPadding {
    padding-top: 1.5rem;
  }
  .doubleScalingBottomMargin {
    margin-bottom: 6rem;
  }
  .hugeScalingBottomMargin {
    margin-bottom: 12rem;
  }
  .mediumScalingText {
    font-size: calc(12px + 2vmin);
  }
  .largeScalingText {
    font-size: calc(14px + 3vmin);
  }
  .largerScalingText {
    font-size: calc(16px + 3vmin);
  }
}

.neonText {
  color: #fff;
  background: inherit;
  animation: glowingText infinite 2s linear;
}
.neonTextClickable {
  color: #fff;
  background: inherit;
  animation: glowingText infinite 2s linear;
}
.neonTextClickable:hover {
  animation: glowingTextHover infinite 2s linear;
}
.neonTextClickable:focus {
  animation: glowingTextFocus infinite 2s linear;
}
.neonTextClickable:active {
  animation: glowingTextFocus infinite 2s linear;
}
.unpoweredNeonText {
  background: -webkit-linear-gradient(white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.unpoweredButtonText {
  background: -webkit-linear-gradient(white, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.unpoweredButtonText:hover {
  background: -webkit-linear-gradient(var(--hover), var(--hover));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.unpoweredButtonText:focus {
  background: -webkit-linear-gradient(var(--focus), var(--focus));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.unpoweredButtonText:active {
  background: -webkit-linear-gradient(var(--focus), var(--focus));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.andrewEasterEggPowered:hover {
  cursor: url(https://cur.cursors-4u.net/symbols/sym-1/sym57.cur), auto;
}

.constantRotation {
  animation: fullRotation infinite 20s linear;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01s !important;
    animation-iteration-count: 0.01s !important;
    transition-duration: 0.01s !important;
  }
}

/* .rainbowBackground:before{
	content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: -1;
	background-image: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
    );
		-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
	animation: fullRotation infinite 1s linear;
} */
/* .rainbowBackground span{
  background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
		background-repeat: repeat-x;
} */

/* ---------- */
/* KEYFRAMES */
@keyframes rainbowLetterAnimation {
  0% {
    -webkit-text-fill-color: hsl(0, 50%, 50%);
    -webkit-text-stroke-color: hsl(0, 50%, 50%);
  }
  10% {
    -webkit-text-fill-color: hsl(324, 50%, 50%);
    -webkit-text-stroke-color: hsl(324, 50%, 50%);
  }
  20% {
    -webkit-text-fill-color: hsl(288, 50%, 50%);
    -webkit-text-stroke-color: hsl(288, 50%, 50%);
  }
  30% {
    -webkit-text-fill-color: hsl(252, 50%, 50%);
    -webkit-text-stroke-color: hsl(252, 50%, 50%);
  }
  40% {
    -webkit-text-fill-color: hsl(216, 50%, 50%);
    -webkit-text-stroke-color: hsl(216, 50%, 50%);
  }
  50% {
    -webkit-text-fill-color: hsl(180, 50%, 50%);
    -webkit-text-stroke-color: hsl(180, 50%, 50%);
  }
  60% {
    -webkit-text-fill-color: hsl(144, 50%, 50%);
    -webkit-text-stroke-color: hsl(144, 50%, 50%);
  }
  70% {
    -webkit-text-fill-color: hsl(108, 50%, 50%);
    -webkit-text-stroke-color: hsl(108, 50%, 50%);
  }
  80% {
    -webkit-text-fill-color: hsl(72, 50%, 50%);
    -webkit-text-stroke-color: hsl(72, 50%, 50%);
  }
  90% {
    -webkit-text-fill-color: hsl(36, 50%, 50%);
    -webkit-text-stroke-color: hsl(36, 50%, 50%);
  }
  100% {
    -webkit-text-fill-color: hsl(360, 50%, 50%);
    -webkit-text-stroke-color: hsl(360, 50%, 50%);
  }
}

@keyframes fullRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spoolRotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@-moz-keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@-o-keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@-ms-keyframes expandY {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes backgroundCoverExpansion {
  from {
    background-size: 100%;
  }
  to {
    background-size: 300%;
  }
}

/* @keyframes neonFadeIn {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
} */
@keyframes neonFadeIn {
  0% {
    filter: opacity(25%);
  }
  50% {
    filter: opacity(40%);
  }
  75% {
    filter: opacity(60%);
  }
  90% {
    filter: opacity(80%);
  }
  100% {
    filter: opacity(100%);
  }
}
@keyframes fadeIn {
  0% {
    filter: opacity(0%);
  }
  100% {
    filter: opacity(100%);
  }
}

@-moz-keyframes fadeIn {
  0% {
    filter: opacity(0%);
  }
  100% {
    filter: opacity(100%);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    filter: opacity(0%);
  }
  100% {
    filter: opacity(100%);
  }
}

@-o-keyframes fadeIn {
  0% {
    filter: opacity(0%);
  }
  100% {
    filter: opacity(100%);
  }
}

@-ms-keyframes fadeIn {
  0% {
    filter: opacity(0%);
  }
  100% {
    filter: opacity(100%);
  }
}

/* TEXT SHADOW GLOWS */
@keyframes glowingText {
  0% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%),
      0 0 10px hsl(200, 100%, 75%), 0 0 21px hsl(200, 100%, 55%),
      0 0 30px hsl(200, 100%, 55%), 0 0 40px hsl(200, 100%, 55%);
  }
  50% {
    text-shadow: 0 0 7px #fff, 0 0 10px hsl(180, 100%, 95%),
      0 0 21px hsl(200, 100%, 75%), 0 0 42px hsl(200, 100%, 55%),
      0 0 60px hsl(200, 100%, 55%), 0 0 80px hsl(200, 100%, 55%);
  }
  100% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(180, 100%, 95%),
      0 0 10px hsl(200, 100%, 75%), 0 0 21px hsl(200, 100%, 55%),
      0 0 30px hsl(200, 100%, 55%), 0 0 40px hsl(200, 100%, 55%);
  }
}

@keyframes glowingTextHover {
  0% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(297, 100%, 95%),
      0 0 10px hsl(297, 100%, 75%), 0 0 21px hsl(297, 100%, 58%),
      0 0 30px hsl(297, 100%, 55%), 0 0 40px hsl(297, 100%, 55%);
  }
  50% {
    text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 95%),
      0 0 21px hsl(297, 100%, 75%), 0 0 42px hsl(297, 100%, 55%),
      0 0 60px hsl(297, 100%, 55%), 0 0 80px hsl(297, 100%, 55%);
  }
  100% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(297, 100%, 95%),
      0 0 10px hsl(297, 100%, 75%), 0 0 21px hsl(297, 100%, 55%),
      0 0 30px hsl(297, 100%, 55%), 0 0 40px hsl(297, 100%, 55%);
  }
}
@keyframes glowingTextFocus {
  0% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(297, 100%, 75%),
      0 0 10px hsl(297, 100%, 55%), 0 0 21px hsl(297, 100%, 50%),
      0 0 30px hsl(297, 100%, 50%), 0 0 40px hsl(297, 100%, 50%),
      0 0 50px hsl(297, 100%, 50%), 0 0 60px hsl(297, 100%, 50%);
  }
  50% {
    text-shadow: 0 0 7px #fff, 0 0 10px hsl(297, 100%, 75%),
      0 0 21px hsl(297, 100%, 55%), 0 0 42px hsl(297, 100%, 50%),
      0 0 60px hsl(297, 100%, 50%), 0 0 80px hsl(297, 100%, 50%),
      0 0 100px hsl(297, 100%, 50%), 0 0 120px hsl(297, 100%, 50%);
  }
  100% {
    text-shadow: 0 0 3px #fff, 0 0 5px hsl(297, 100%, 75%),
      0 0 10px hsl(297, 100%, 55%), 0 0 21px hsl(297, 100%, 50%),
      0 0 30px hsl(297, 100%, 50%), 0 0 40px hsl(297, 100%, 50%),
      0 0 50px hsl(297, 100%, 50%), 0 0 60px hsl(297, 100%, 50%);
  }
}

/* BOX SHADOW GLOWS */
@keyframes navBarPoweredGlow {
  0% {
    /* filter: drop-shadow(0 0 0px 0px white, 0 0 0px 0px var(--power1),
		0 0 1px 1px var(--power3)); */
    /* box-shadow: 0 0 0px 0px white, 0 0 0px 0px var(--power1),
      0 0 1px 1px var(--power3); */
    filter: drop-shadow(0 0 5px white);
  }
  50% {
    /* filter: drop-shadow(0 0 3px 3px white, 0 0 6px 6px var(--power1),
		0 0 9px 9px var(--power3)); */
    /* box-shadow: 0 0 1px 1px white, 0 0 2px 2px var(--power1),
      0 0 3px 3px var(--power3); */
    filter: drop-shadow(0 0 10px white);
  }
  100% {
    /* filter: drop-shadow(0 0 0px 0px white, 0 0 0px 0px var(--power1),
		0 0 1px 1px var(--power3)); */
    /* box-shadow: 0 0 0px 0px white, 0 0 0px 0px var(--power1),
      0 0 1px 1px var(--power3); */
    filter: drop-shadow(0 0 5px white);
  }
}
@keyframes poweredGlow {
  0% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--power2),
      0 0 2px 2px var(--power3);
  }
  50% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--power2),
      0 0 5px 5px var(--power3);
  }
  100% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--power2),
      0 0 2px 2px var(--power3);
  }
}

@keyframes homeLogoGlow {
  0% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
  50% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--hover),
      0 0 5px 5px var(--focus);
  }
  100% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
}
@keyframes homeLogoGlowOff {
  0% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px hsl(280, 100%, 0%, 1),
      0 0 2px 2px hsla(280, 88%, 0%, 0.5);
  }
  50% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px hsla(280, 100%, 0%, 1),
      0 0 5px 5px hsla(280, 88%, 0%, 0.5);
  }
  100% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px hsla(280, 100%, 0%, 1),
      0 0 2px 2px hsla(280, 88%, 0%, 0.5);
  }
}
@keyframes homeLogoGlowFocus {
  0% {
    filter: hue-rotate(0deg);
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
  12.5% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--hover),
      0 0 5px 5px var(--focus);
  }
  25% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
  37.5% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--hover),
      0 0 5px 5px var(--focus);
  }
  50% {
    filter: hue-rotate(360deg);
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
  62.5% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--hover),
      0 0 5px 5px var(--focus);
  }
  75% {
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
  87.5% {
    box-shadow: 0 0 3px 3px white, 0 0 4px 4px var(--hover),
      0 0 5px 5px var(--focus);
  }
  100% {
    filter: hue-rotate(0deg);
    box-shadow: 0 0 1px 1px white, 0 0 1px 1px var(--hover),
      0 0 2px 2px var(--focus);
  }
}
@keyframes syncroSuccessColor {
  0% {
    color: var(--hot-pink);
  }
  100% {
    color: var(--white);
  }
}

@keyframes hueShift {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@keyframes inverseHueShift {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-360deg);
  }
}
@keyframes aboutPoweredBanner {
  0% {
    filter: contrast(120%);
  }
	50% {
		filter: contrast(180%);
	}
  100% {
    filter: contrast(120%);
  }
}

/* Thanks to https://css-tricks.com/snippets/css/typewriter-effect/ */

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: var(--power3); }
}